


















































































































































































































import Vue from 'vue';
import { Tag } from '@/interface';
import MyEditor from '@/components/global/myEditor/index.vue';
import { message } from 'ant-design-vue';
import * as shelfApi from '@/api/appShelf';
import { trim } from 'lodash';
import moment from 'moment';
//货架资源项接口定义
interface shelf {
  key: string;
  appName: string;
  appCode: string;
  clientId: string;
  img: string;
  menuResource: string;
  operateResource: string;
  applicationName: string;
  applicationLogo: string;
}
export default Vue.extend({
  name: 'createForm',
  components: { MyEditor },
  data() {
    return {
      tableLoading: false,
      submitLoading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      mainForm: {
        prodName: '',
        productSpuResourceDTOS: [] as any, //资源列表
        prodDetails: '', //HTML的资源详情-用于展示
        prodDetailsJson: '', //JSON格式的资源详情-用于编辑
        validPeriodType: '0', //货架有效期
        validPeriod: undefined, //截至日期
        attributeInstanceDTOS: [] as any, //属性
        productSkuDTOS: [] as any, //货架规格
        categoryCode: '', // 订购方式CODE
        categoryName: '', // 订购方式中文名称
        tagCode: 'xunishangpin', // 后台默认值，标签
      },
      mainFormRules: {
        prodName: [
          { required: true, message: '请输入货架名称', trigger: 'blur' },
          {
            min: 0,
            max: 20,
            message: '货架名称长度不能超过20位',
            trigger: 'blur',
          },
        ],
        productSpuResourceDTOS: [{ required: true, message: '请选择产品' }],
        productSkuDTOS: [
          {
            required: true,
            validator: this['validateProductSkuDTOS'],
          },
        ],
        validPeriodType: [{ required: true, message: '请选择货架有效期' }],
        categoryCode: [{ required: true, message: '请选择订购方式' }],
        validPeriod: [
          { required: true, message: '请选择周期范围', trigger: 'change' },
        ],
      },
      specificationsColumns: [
        {
          title: '单位',
          align: 'center',
          width: 120,
          dataIndex: 'skuName',
        },
        {
          title: '划线价（元）',
          align: 'center',
          dataIndex: 'originalPrice',
          scopedSlots: { customRender: 'originalPrice' },
        },
        {
          title: '售价（元）',
          align: 'center',
          dataIndex: 'sellingPrice',
          scopedSlots: { customRender: 'sellingPrice' },
        },
        {
          title: '操作',
          align: 'center',
          width: 80,
          scopedSlots: { customRender: 'action' },
        },
      ],
      specificationsData: [] as any,
      dynamicTableHeader: [] as any, //sku 动态表头临时存储
      categoryCodeList: [] as any, //订购方式 下拉列表数据
      // 产品选择列表弹框
      showModal: false,
      modalTitle: '选择产品',
      productName: '', //已选产品名称
      searchForm: {
        applicationName: '', // 优惠券名称
      },
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      productInfoLoading: false,
      productInfoColumns: [
        {
          title: '序号',
          align: 'left',
          dataIndex: 'number',
          width: '80px',
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: '产品名称',
          dataIndex: 'applicationName',
          align: 'left',
          scopedSlots: { customRender: 'applicationName' },
        },
        {
          title: '产品描述',
          dataIndex: 'description',
          align: 'left',
          scopedSlots: { customRender: 'description' },
        },
      ],
      productInfoData: [] as any,
      selectedRowKeys: [], //选中的数据key
      selectedRows: [], //选中的数据
    };
  },
  mounted() {
    this.getCategoryCodeList();
  },
  methods: {
    moment,
    /**
     * 限制货架名称前后空字符串
     */
    updateProdName(e) {
      this.$set(this.mainForm, 'prodName', trim(e.target.value));
    },
    range(start: number, end: number): number[] {
      const result: number[] = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },
    /**
     * 限制天
     */
    disabledDate(current) {
      return current && current < moment().startOf('day');
    },
    /**
     * 限制时分秒
     */
    disabledDateTime(current) {
      let h = moment().hour();
      let m = moment().minute();
      if (current > moment().endOf('day')) {
        h = 0;
      }
      if (current > moment().endOf('hour')) {
        m = 0;
      }
      return {
        disabledHours: () => this.range(0, h),
        disabledMinutes: () => this.range(0, m),
        disabledSeconds: () => [],
      };
    },
    validateProductSkuDTOS(rule, value, callback) {
      if (value.length === 0) {
        callback(new Error('请至少选择一个货架规格'));
      } else {
        for (let item of value) {
          if (
            typeof item.originalPrice !== 'number' ||
            typeof item.sellingPrice !== 'number'
          ) {
            callback(new Error('请输入正确的划线价与售价'));
          }
        }
        callback();
      }
    },
    createTableHeader(headerArr) {
      //生成动态表头
      const currentArr = headerArr.map((item, index) => {
        return {
          title: item.attrName,
          align: 'center',
          width: 120,
          dataIndex: item.attrCode + index,
        };
      });
      this.dynamicTableHeader = currentArr;
      this.specificationsColumns = [
        ...currentArr,
        {
          title: '划线价（元）',
          align: 'center',
          dataIndex: 'originalPrice',
          scopedSlots: { customRender: 'originalPrice' },
        },
        {
          title: '售价（元）',
          align: 'center',
          dataIndex: 'sellingPrice',
          scopedSlots: { customRender: 'sellingPrice' },
        },
        {
          title: '操作',
          align: 'center',
          width: 80,
          scopedSlots: { customRender: 'action' },
        },
      ];
    },
    /* 获取订购方式下拉菜单 */
    getCategoryCodeList() {
      shelfApi.getCategoryCodeList(1, 999, 'SOFTWARE_SERVICE').then((res) => {
        if (res.status === 200) {
          /* 赋值订购方式下拉菜单 */
          this.categoryCodeList = res.result.list;
          /* 默认选中列表第一项 */
          const { categoryCode, categoryName } = res.result.list[0];
          this.mainForm.categoryCode = categoryCode;
          this.mainForm.categoryName = categoryName;
          /* 初始化货架 */
          this.getSpecificationsData();
        }
      });
    },
    /* 订购方式Change */
    handleCategoryCodeChange(event) {
      const currentObj = this.categoryCodeList.find(
        (item) => item.categoryCode === event.target.value
      );
      // 赋值 categoryName ，用于后台保存label文字给主列表用
      this.mainForm.categoryName = currentObj.categoryName;

      this.getSpecificationsData();
    },
    getSpecificationsData() {
      shelfApi
        .getSpecificationsData(1, 9999, this.mainForm.categoryCode)
        .then((res) => {
          if (res.status === 200) {
            //生成动态表头
            this.createTableHeader(res.result);
            const skuParam = res.result.map((item) => {
              return {
                attrName: item.attrName,
                attrCode: item.attrCode,
                attributeValueInstanceDTOS:
                  item.productAttributeValueDefinitionDTO,
              };
            });
            this.mainForm.attributeInstanceDTOS = skuParam; // 赋值属性
            this.tableLoading = true;
            shelfApi
              .createSkuList(skuParam)
              .then((res) => {
                if (res.status === 200) {
                  // 赋值sku表格
                  this.specificationsData = res.result.map((item, index) => {
                    const names = item.skuName.split('|');
                    return {
                      ...item,
                      ...this.dynamicTableHeader.reduce((prev, cur, index) => {
                        prev[cur.dataIndex] = names[index];
                        return prev;
                      }, {}),
                      key: item.skuCode,
                      available: index ? false : true,
                    };
                  });
                }
              })
              .finally(() => {
                this.tableLoading = false;
              });
          }
        });
    },
    handleInputChange(value: number, key: string, dataIndex: string) {
      this.specificationsData = this.specificationsData.map((item) => {
        if (item.key === key) {
          return {
            ...item,
            [dataIndex]: value, //变更 dataIndex 属性
          };
        }
        return item;
      });
    },
    handleActionChange(key: string, checked: boolean) {
      this.specificationsData = this.specificationsData.map((item) => {
        if (item.key === key) {
          return {
            ...item,
            available: checked, //变更 available 属性
          };
        }
        return item;
      });
    },
    onMainFormCancel() {
      // 主表单取消
      this.closeCurrentTags('/operate/appShelf/create', '/operate/appShelf');
    },
    onMainFormSubmit() {
      this.mainForm.prodDetails = (
        this.$refs.editor as HTMLFormElement
      ).latestContentHtml;
      this.mainForm.prodDetailsJson = (
        this.$refs.editor as HTMLFormElement
      ).latestContentJson;
      // 赋值货架规格
      const dataSource = [...this.specificationsData];
      this.mainForm.productSkuDTOS = dataSource.filter((item) => {
        return item.available;
      });
      //主表单提交
      (this.$refs.mainForm as HTMLFormElement).validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          shelfApi
            .addAppShelf(this.mainForm)
            .then((res) => {
              if (res.status === 200) {
                if (res.result) {
                  this.closeCurrentTags(
                    '/operate/appShelf/create',
                    '/operate/appShelf'
                  );
                  this.$destroy();
                }
              }
            })
            .finally(() => {
              this.submitLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 打开产品信息列表弹框
    showProductInfoModal() {
      this.showModal = true;
      this.getProductInfoList();
    },
    // 产品信息列表组合查询表单提交
    handleSearchSubmit() {
      this.pagination.current = 1;
      this.getProductInfoList();
    },
    // 产品信息列表组合查询表单重置
    handleSearchReset() {
      (this.$refs.searchFormRef as HTMLFormElement).resetFields();
      this.pagination.current = 1;
      this.getProductInfoList();
    },
    // 产品信息列表翻页控制
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getProductInfoList();
    },
    // 产品信息列表行选择事件
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    //产品信息列表表单取消事件
    handleFormCancel() {
      this.showModal = false;
      this.clearSelectdRows();
    },
    //产品信息列表弹框关闭事件
    handleModalCancel() {
      this.clearSelectdRows();
    },
    //产品信息列表表单保存事件
    handleFormSave() {
      if (this.selectedRows.length) {
        this.mainForm.productSpuResourceDTOS = this.selectedRows.map(
          (item: shelf) => {
            return {
              key: item.clientId,
              appCode: item.clientId,
              appName: item.applicationName,
              img: item.applicationLogo,
            };
          }
        );
        //赋值主表产品名称
        this.productName = this.mainForm.productSpuResourceDTOS[0].appName;
        this.showModal = false;
        this.clearSelectdRows();
        (this.$refs.mainForm as HTMLFormElement).validateField([
          'productSpuResourceDTOS',
        ]);
      } else {
        message.error('请选择产品！');
      }
    },
    //产品信息列表清空已选择
    clearSelectdRows() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
    },
    // 获取产品信息列表
    getProductInfoList() {
      this.productInfoLoading = true;
      shelfApi
        .getApplicationList(
          this.pagination.current,
          this.pagination.pageSize,
          1,
          this.searchForm.applicationName
        )
        .then((res) => {
          if (res.status === 200) {
            this.pagination.total = res.result.total;
            this.productInfoData = res.result.list;
          }
        })
        .finally(() => {
          this.productInfoLoading = false;
        });
    },
    closeCurrentTags(currentPath: string, toPath: string) {
      // 关闭当前tag,并跳转到指定页面
      const filterTags = this.$store.state.common.tags.filter(
        (tag: Tag) => tag.path !== currentPath
      );
      this.$store.commit('SET_TAGS', filterTags);
      this.$router.push({ path: toPath });
    },
  },
});
