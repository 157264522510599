/*
 * @Descripttion:
 * @Author: yuyang
 * @Date: 2021-12-07 11:06:13
 * @LastEditors: wuxinxin
 * @LastEditTime: 2022-05-23 10:23:29
 */
import request from '@/utils/request';
import { BaseResponse } from '@/interface';

export function getAppShelfList(
  page: number,
  limit: number,
  prodName: string,
  startTime: number | '',
  endTime: number | '',
  prodStatus: string | undefined
): Promise<BaseResponse> {
  return request({
    url: `/product-resource/1/productSpu`,
    method: 'GET',
    params: {
      page,
      limit,
      prodName,
      startTime,
      endTime,
      prodStatus,
    },
  });
}

export function getSpecificationsData(
  page: number,
  limit: number,
  categoryCode: string
): Promise<BaseResponse> {
  return request({
    url: `/product-resource/1/productAttributeDefinition`,
    method: 'GET',
    params: {
      page: page,
      limit: limit,
      categoryCode: categoryCode,
    },
  });
}

export function createSkuList(data): Promise<BaseResponse> {
  return request({
    url: `/product-resource/1/productSkuCodeBuilderResourceV1`,
    method: 'POST',
    data,
  });
}

export function addAppShelf(data): Promise<BaseResponse> {
  return request({
    url: `/product-resource/1/productSpu`,
    method: 'POST',
    data,
  });
}

export function getAppShelfDetail(id: string): Promise<BaseResponse> {
  return request({
    url: `/product-resource/1/productSpu/${id}`,
    method: 'GET',
  });
}

export function editAppShelf(data): Promise<BaseResponse> {
  return request({
    url: `/product-resource/1/productSpu/productInfo`,
    method: 'put',
    data,
  });
}

export function handleShelfStatus(
  id: string,
  status: number,
  version: string
): Promise<BaseResponse> {
  return request({
    url: `/product-resource/1/productSpu/${id}/${status}/${version}`,
    method: 'put',
  });
}

export function getCategoryCodeList(
  page: number,
  limit: number,
  businessType: string
): Promise<BaseResponse> {
  return request({
    url: `/sys-resource/1/sysCategory`,
    method: 'GET',
    params: {
      page,
      limit,
      businessType,
    },
  });
}

export function getApplicationList(
  page: number,
  limit: number,
  bizStatus: number | null,
  applicationName: string | null
): Promise<BaseResponse> {
  return request({
    url: `/product-resource/1/application`,
    method: 'GET',
    params: {
      page: page,
      limit: limit,
      bizStatus: bizStatus,
      applicationName: applicationName,
    },
  });
}

export function editSort(data): Promise<BaseResponse> {
  return request({
    url: `/product-resource/1/productSpu/sort`,
    method: 'put',
    data,
  });
}

export function resetSort(): Promise<BaseResponse> {
  return request({
    url: `/product-resource/1/productSpu/clearAllSpuSort`,
    method: 'PUT',
  });
}

export function sortList(
  page: number,
  limit: number,
  prodName: string,
  startTime: number | '',
  endTime: number | '',
  prodStatus: string | undefined,
  orderField: string,
  order: string
): Promise<BaseResponse> {
  return request({
    url: `/product-resource/1/productSpu`,
    method: 'GET',
    params: {
      page,
      limit,
      prodName,
      startTime,
      endTime,
      prodStatus,
      orderField,
      order,
    },
  });
}
